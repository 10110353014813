











import { Component, Prop, Vue } from "vue-property-decorator";
import axios from "axios";
import { getOu } from "@/utils/cookies";
import { Store } from "vuex";
@Component({
  name: "ExportButton",
})
export default class ExportButton extends Vue {
  @Prop({ required: true })
  queryModel!: any;

  @Prop({ required: true })
  url!: string;
  @Prop({ required: true })
  fileTitle!: string;
  @Prop({ required: false })
  buttonTitle!: string;

  @Prop({ required: false, default: false })
  disabled!: boolean;

  loading = false;

  handleExport() {
    this.loading = true;
    const store = this.$store as Store<any>;
    axios(this.url, {
      method: "POST",
      baseURL: process.env.VUE_APP_BASE_API,
      data: this.queryModel,
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${store.getters["user/token"]}`,
        ".AspNetCore.Culture": "c=zh-Hans|uic=zh-Hans",
        "Abp.ManagePlatform": true,
        "Abp-OrganizationUnitId": store.getters["user/ouId"],
        "Abp-TenantId": store.getters["app/tenantId"],
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (!res) {
        this.loading = false;
        return;
      }
      let url = window.URL.createObjectURL(new Blob([res.data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", `${this.fileTitle}.xls`);

      document.body.appendChild(link);
      setTimeout(() => {
        link.click();
        this.loading = false;
      }, 300);
    });
  }
}
